(function ($) {
  Drupal.behaviors.subnavMenuV1 = {
    attach: function (context) {
      var $template = $('.js-subnav__menu__formatter--v1', context);

      $template.each(function (index, el) {
        var $subNavMenu = $('.js-subnav__menu__formatter__item', this);
        var menuCount = $subNavMenu.length;

        if (!!menuCount) {
          $(this).addClass('subnav__menu__count--' + menuCount);
        }
      });
    }
  };
})(jQuery);
